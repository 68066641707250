.chatbot__rater g {
    stroke: #3C1C47;
    background: transparent;
}

.chatbot__rater.rater-active svg.rater-like:hover g,
.chatbot__rater.rater-active svg.rater-dislike:hover g {
    fill: #3C1C47;
}

.chatbot__rater svg.rater-selected g {
    fill: #3C1C47;
}

.chatbot__container-mobileAppMode .chatbot__mobileAppMode {
    background-color: #F0F3F9;
}

div.chatbot__container .chatbot__header {
    background-color: #3C1C47;
}

.chatbot__submitIconActive {
    fill: #954FEF;
}

div.chatbot__container .chatbot__submitIcon.open path {
    fill: #954FEF;
}

div.chatbot__chatToggleContainer .chatbot__chatToggle {
    background: linear-gradient(180deg, #954FEF 0%, #3C1C47 100%);
    background: -webkit-linear-gradient(top, #954FEF 0%, #3C1C47 100%);
}

div.chatbot__message {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.chatbot__tooltip {
    background-color: #954FEF !important;
}

.chatbot__tooltip::after {
  background-color: #954FEF !important;
}

.chatbot__container .chatbot__answerMessage {
    background-color: #954FEF;
    color: #fff;
}

.chatbot__container .chatbot__answerMessage a {
    color: #fff;
    text-decoration: underline;
}

.chatbot__container .chatbot__questionMessage {
    color: #3C1C47;
    background-color: #E4E6EA;
}

div.chatbot__container .chatbot__answerOption {
    border: 1px solid #fff;
    color: #fff;
}
